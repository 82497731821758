.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.canvas {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 85vw;
}

.mymap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.tooltip {
  position: absolute;
  width: 250px;
  max-height: 500px;
  background-color: white;
  border: solid black thin;
  border-radius: 5pt;
  padding: 10px 10px;
  /* overflow: auto; */
}

.ttheader {
  margin-top: 0;
  margin-bottom: 0;
}

.legend {
  position: absolute;
  top: 75px;
  left: 40px;
  background-color: white;
  border: solid black 1px;
  border-radius: 5pt;
  padding: 10px 10px;
  align-items: center;
}

.legend-btn {
  position: absolute;
  top: 75px;
  left: 40px;
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  border: solid black 1px;
}

.orange {
  background-color: orange
}

.red {
  background-color: red;
}

.blue {
  background-color: blue;
}

.pink {
  background-color: Fuchsia;
}

.green {
  background-color: green;
}

.yellow {
  background-color: yellow;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 158px;
  left: 0px;
  margin-left: -21px;
  border-width: 12px;
  border-style: solid;
  border-color: transparent white transparent transparent;
}

.tooltip::before {
  content: "";
  position: absolute;
  top: 160px;
  left: -.5px;
  margin-left: -20px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent black transparent transparent
}

.center-text {
  text-align: center;
}

.title {
  /* position: absolute; */
  /* bottom: 35px; */
  /* left: calc(50% - 200px); */
  background-color: white;
  padding: 0 15px;
  border: solid black thin;
  border-radius: 5pt;
}

.rightInfo {
  position: absolute;
  top: 85px;
  right: 10px;
  max-width: 450px;
  max-height: 85vh;
  background-color: white;
  border: solid black thin;
  overflow: auto;
}

.title h3 {
  margin: 5px;
}

.title p {
  margin: 0;
}

.smaller {
  font-size: smaller;
}

.rangeSlider {
  position: absolute;
  top: 35px;
  left: 0;
  height: 350px;
  padding: 20px 75px 0 15px;
}

.btn {
  background-color: white;
  border: solid black 1px;
  border-radius: 5pt;
  padding: 10px;
  cursor: pointer;
}

.btn:hover {
  background-color: lightgrey;
}

.table-btn {
  position: absolute;
  top: 45px;
  right: 10px;
}

.map-btn {
  position: absolute;
  top: 45px;
  right: 100px;
}

.text-center {
  text-align: center;
}

.headerHighlight {
  background-color: rgba(25, 118, 210, 0.08);
  cursor: pointer;
}

tr:focus {
  background-color: lightgrey;
}

.leftLabel {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  border: solid black thin;
  padding: 2px;
  border-radius: 3pt;
}

.rightLabel {
  position: absolute;
  top: 420px;
  left: 10px;
  background-color: white;
  border: solid black thin;
  padding: 2px;
  border-radius: 3pt;
}

.pointer {
  cursor: pointer;
}

.search {
  position: absolute;
  top: 15px;
  right: 10px;
  padding: 3px;
}

.mapPop {
  /* position: absolute;
  bottom: 50px;
  left: 10px; */
  /* background-color: white;
  border: solid black thin;
  border-radius: 5pt; */
  padding: 5px
}

.shift {
  text-align: center;
  font-size: 0.8em;
  margin-top: -25px;
  /* margin-bottom: 0px; */
}

.number {
  /* position: absolute;
  bottom: 10px;
  left: 10px; */
  /* background-color: white;
  border: solid black thin;
  border-radius: 5pt; */
  padding: 5px
}

.sourcing {
  position: absolute;
  bottom: 0px;
  left: 10px;
  background-color: white;
  border: solid black thin;
  border-radius: 5pt;
  padding: 5px
}

.close-landing {
  position: absolute;
  top: 10px;
  right: 12px;
  cursor: pointer;
}

.landing-page {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  max-height: 450px;
  background-color: white;
  border: solid black;
  border-radius: 5pt;
  padding: 15px;
  overflow: auto;
}

.footer-div {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  bottom: 5px;
  width: 100%;
}

.footer {
  position: absolute;
  display: flex;
  bottom: 5px;
  width: 75%;
  background-color: white;
  align-items: center;
  border-radius: 5pt;
  border: solid black thin;
  justify-content: space-around;
}

/* .css-y35fuw-MuiSlider-thumb:before {
  background-color: white !important;
  border: solid black 1px !important;
}

.css-y50uh2-MuiSlider-root {
  color: black !important;
} */